export default function getEnvironment() {
    let APP_ENVIRONMENT = process.env.VUE_APP_ENVIRONMENT;
    let API_URL, API_LOGGED_IN_URL, FRONTEND_URL;

    if(APP_ENVIRONMENT === '0') {
        API_URL = process.env.VUE_APP_API_URL;
        API_LOGGED_IN_URL = process.env.VUE_APP_API_LOGGED_IN_URL;
        FRONTEND_URL = process.env.VUE_APP_FRONTEND_URL;
    } else if(APP_ENVIRONMENT === '1') {
        API_URL = process.env.VUE_APP_DEV_API_URL;
        API_LOGGED_IN_URL = process.env.VUE_APP_DEV_API_LOGGED_IN_URL;
        FRONTEND_URL = process.env.VUE_APP_FRONTEND_DEV_URL;
    } else {
        API_URL = process.env.VUE_APP_PROD_API_URL;
        API_LOGGED_IN_URL = process.env.VUE_APP_PROD_API_LOGGED_IN_URL;
        FRONTEND_URL = process.env.VUE_APP_FRONTEND_PROD_URL;
    }

    return {
        API_URL: API_URL,
        API_LOGGED_IN_URL: API_LOGGED_IN_URL,
        FRONTEND_URL: FRONTEND_URL,
    };
}