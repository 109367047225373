export default function authHeader() {
    let token = JSON.parse(localStorage.getItem('user'));

    if (token) {
        return {
            'X-AUTH-TOKEN' : token
        };
    } else {
        return {};
    }
}
