import { createRouter, createWebHashHistory } from 'vue-router';
import App from './App.vue';
import AuthService from "@/service/AuthService";

const routes = [
    {
        path: '',
        component: () => import('./pages/Login.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login.vue')
    },
    {
        path: '/',
        name: 'app',
        component: App,
        children: [
            {
                path: '/profile',
                name: 'profile',
                component: () => import('./views/Profile/Profile')
            },
            {
                path: '/users',
                name: 'users',
                component: () => import('./views/User/Users')
            },
            {
                path: '/admins',
                name: 'admins',
                component: () => import('./views/Admin/Admins')
            },
            {
                path: '/events',
                name: 'events',
                component: () => import('./views/Event/Events')
            },
            {
                path: '/stats',
                name: 'stats',
                component: () => import('./views/Stat/Stats')
            },
            {
                path: '/messages',
                name: 'messages',
                component: () => import('./views/Message/Messages')
            },
            {
                path: '/settings',
                name: 'settings',
                component: () => import('./views/Setting/Settings')
            },
            {
                path: '/general-terms',
                name: 'general_terms',
                component: () => import('./views/GeneralTermsAndCondition/GeneralTermsAndConditions')
            },
            {
                path: '/privacy-statements',
                name: 'privacy_statements',
                component: () => import('./views/PrivacyStatement/PrivacyStatements')
            },
            {
                path: '/languages',
                name: 'languages',
                component: () => import('./views/Language/Languages')
            },
        ]
    },
    {
        path: '/landing',
        name: 'landing',
        component: () => import('./pages/LandingDemo.vue')
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/Error.vue')
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('./pages/NotFound.vue')
    },
    {
        path: '/access',
        name: 'access',
        component: () => import('./pages/Access.vue')
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    const user = localStorage.getItem('user');
    const expire = localStorage.getItem('expire');

    if ((!user || Date.now() >= new Date(expire)) && (to.fullPath !== '/')) {
        if (to.fullPath === '/forgotten-password') {
            next();
        } else {
            AuthService.logout();
            next('/');
        }
    } else {
        next();
    }
});

export default router;
